<template>
  <form class="form-info" :class="{skeleton: !state.loaded}" @submit.prevent="submit()">
    <div class="wrapper">
      <div class="head">
        <div class="title">양식 {{ modalParams.type === "add" ? "추가" : "수정" }}</div>
        <button class="remove btn" type="button" title="삭제" @click="remove(modalParams.todoSeq)" v-if="modalParams.type !== 'add'">
          <i class="fa fa-trash"></i>
        </button>
      </div>
      <div class="body">
        <div class="form-group">
          <label class="font-xs" :for="`${component.name}Subject`">
            <span class="color-purple">*</span>
            <span> 제목</span>
          </label>
          <input :id="`${component.name}Title`" v-model="state.archive.title" type="text" class="form-control"
                 placeholder="ex) 리뷰 설문조사 2023년 하반기" autocomplete="off" maxlength="100"/>
        </div>
        <div class="form-group">
          <label class="font-xs" :for="`${component.name}Category`">
            <span class="color-purple">*</span>
            <span> 분류</span>
          </label>
          <select :id="`${component.name}Category`" class="form-control" v-model="state.archive.categoryId">
            <option :value="null">분류를 선택해주세요.</option>
            <option :value="c.id" v-for="(c, idx) in state.categories" :key="idx">{{ c.title }}</option>
          </select>
        </div>
        <div class="form-group">
          <label class="font-xs" :for="`${component.name}Desc`">설명</label>
          <textarea :id="`${component.name}Desc`" v-model="state.archive.description" type="text" class="form-control" @keyup.ctrl.enter="submit()"
                    maxlength="50" placeholder="ex) 리뷰 설문조사는 금년도 12월 31일까지 진행합니다." autocomplete="off"></textarea>
        </div>
        <div class="form-group">
          <label class="font-xs" :for="`${component.name}guideName`">
            <span> 가이드 이름</span>
          </label>
          <input :id="`${component.name}GuideName`" v-model="state.archive.guideName" type="text" class="form-control"
                 placeholder="ex) 증권형 프로젝트 이용가이드" autocomplete="off" maxlength="100"/>
        </div>
        <div class="form-group mb-0">
          <label class="font-xs" :for="`${component.name}guideUrl`">
            <span> 가이드 주소</span>
          </label>
          <input :id="`${component.name}GuideUrl`" v-model="state.archive.guideUrl" type="text" class="form-control"
                 placeholder="ex) https://corp.ohmycompany.com/guide" autocomplete="off" maxlength="100"/>
        </div>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-purple btn-block" type="submit">저장하기</button>
    </div>
  </form>
</template>

<script>
import {defineComponent, onMounted, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import lib from "@/scripts/lib";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "modalArchiveInfo";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      if (modalParams.archive) {
        state.archive = lib.getRenewed(modalParams.archive);
      }

      state.loaded = false;
      http.get("/api/admin/archive/categories").then(({data}) => {
        state.loaded = true;
        state.categories = data.body.content;
      });

      store.commit("tuneModal", {component, size: "sm"});
    });

    const state = reactive({
      loaded: true,
      categories: [],
      archive: {
        title: "",
        description: "",
        categoryId: null,
        step: {
          title: "기본 정보",
          ord: 1,
        },
        guideName: "",
        guideUrl: "",
      }
    });

    const modalParams = store.getters.modalParams(component);

    const submit = () => {
      if (!state.archive.title?.trim()) {
        document.getElementById(`${component.name}Title`)?.focus();
        return store.commit("setSwingMessage", "제목을 입력해주세요.");
      }

      const onClose = () => {
        store.commit("setSwingMessage", "양식 정보를 저장하였습니다.");
        close();
      };

      if (modalParams.type === "add") {
        http.post("/api/admin/archives", state.archive).then(onClose);
      } else {
        http.put(`/api/admin/archives/${state.archive.id}`, state.archive).then(onClose);
      }
    };

    const close = () => {
      store.commit("closeModal", {
        name: component.name,
        onClose(modal) {
          store.dispatch("callback", {modal});
        }
      });
    };

    const remove = () => {
      store.commit("confirm", {
        message: "삭제하시겠습니까?",
        allow() {
          http.delete(`/api/admin/archives/${modalParams.archive.id}`).then(() => {
            store.commit("setSwingMessage", "양식을 삭제하였습니다.");
            close();
          });
        }
      });
    };

    onMounted(() => {
      modalParams.type === "add" && document.getElementById(`${component.name}Title`)?.focus();
    });

    return {component, state, modalParams, submit, close, remove,};
  }
});
</script>

<style lang="scss" scoped>
.form-info {
  > .wrapper {
    background: #fff;
    padding: $px25;
  }

  .head {
    position: relative;
    margin-bottom: $px20;

    .title {
      font-size: $px18;
    }

    .remove {
      position: absolute;
      top: 0;
      right: 0;
      padding: $px9 $px5;
      margin: $px-5;
    }
  }

  .body {
    .form-group {
      font-size: $px14;

      input, textarea, select {
        background-color: #fff;
        font-size: $px13;
        transition: none;
      }

      input, textarea {
        padding: $px15;
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px60;
      border-radius: 0;
      font-size: $px13;

      &.remove {
        background: $colorSecondary;
        border-color: $colorSecondary;
        color: #fff;

        &:hover {
          background: $colorSecondaryActive;
          border-color: $colorSecondaryActive;
        }
      }
    }
  }

  &.skeleton {
    .head .remove {
      display: none;
    }

    .body {
      input, textarea, select {
        @include skeleton;
      }
    }
  }
}
</style>